import {createTheme} from '@material-ui/core/styles'
//import {GeneralesContext} from '../context/GeneralesContext'
//import React from 'react';
//const {generales}=React.useContext(GeneralesContext);  
let colorBarra="#24AF88";
let colorBarraHover="#4fbf9f";
let colorBackBarra="#ECFAFB";
//let colorTema="light"//dark
export function generarTema(colorTema) {  

  const Tema = createTheme({
    palette:
    { type:(colorTema ? 'dark' : 'light'),
      primary:{
        light: '#4fbf9f',
        main:'#24AF88',//cambiar 1E8DBD
        dark:'#197a5f',        
        contrastText: '#fff'
      }
      ,secondary:
      {
        light: '#5393ff',
        main:'#04bf8b',
        dark:'#1c54b2',        
        contrastText:'#fff'
      },
      success: 
      {
        light: '#049b4f',
        main:'#06DE72',
        dark:'#37e48e',        
        contrastText:' rgba(0, 0, 0, 0.87)'
      },
      error:{
        light: "#e57373",
        main:  "#f44336",
        dark: "#d32f2f",
        contrastText:  "#fff"
      }
    },
      //modifique el multiline para que se expanda la caja
      overrides: {
        MuiCssBaseline: {
          "@global": {
            body: {           // el hexadecimal  es para el backgroundColor de la barra  #fff1e3
              //el hexadecimal  es para el color de la barra #0EA7E0 
              //el hexadecimal  es para el color del hover de la barra #c4e6f2 
              scrollbarColor: colorBarra+" "+colorBarraHover, //"#0EA7E0 #c4e6f2",
              "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                backgroundColor: colorBackBarra,
              },
              "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                borderRadius: 8,
                backgroundColor: colorBarra,
                minHeight: 24,
                border: "3px solid "+colorBarra,
              },
              "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                backgroundColor: colorBackBarra,
              },
              "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                backgroundColor: colorBackBarra,
              },
              "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                backgroundColor: colorBarraHover,
              },
              "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                backgroundColor: colorBackBarra,
              },
            },
          },
        },
      }
    })

return Tema
}